.input_group{
    @apply flex border rounded-xl;
    position: relative;
}

.input_text{
    @apply w-full py-4 px-6 border rounded-xl bg-slate-50;
    @apply focus:outline-none border-none
}

.button{
    @apply w-full bg-primary rounded-md;
    @apply py-3 text-gray-50 text-lg;
}

.button:hover{
    @apply bg-gradient-to-r from-gray-50 to-gray-100 border-primary;
    @apply text-gray-700 border-2 transition;
}

.button_custom{
    @apply w-full border py-3 flex justify-center gap-2 hover:bg-gray-200
}

.input_text + *{
    color:#cbd5e1;
}

.input_text:focus + *{
    @apply text-primary 
}

.input_text:not([type=email])~ span:hover *{
    @apply text-primary cursor-pointer
    /* cursor:pointer; */
}

